// import React from 'react'
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Layout() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // if ( name.length == '' ||  email.length == '' || message.length == '') {
    // alert('Please fill in all fields');
    // return;
    // }

    const serviceId = "service_tixb41l";
    const templateId = "template_arss4jt";
    const publicKey = "gAeSwEYw7ZHFDaCUw";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Personal Web",
      message: message,
    };
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((res) => {
        setName("");
        setEmail("");
        setMessage("");
        alert("Email sent successfully");
      })
      .catch((error) => {
        alert("Email not sent");
      });
  };

  return (
    <>
      <div className="videoplay" id="home">
        <video autoPlay="autoPlay" muted loop>
          <source src="piesa_de_arta.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="slider" id="about">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="1.png" alt="pic" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="2.png" alt="pic" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="3.png" alt="pic" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="4.png" alt="pic" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="5.png" alt="pic" />
          </SwiperSlide>
        </Swiper>
      </div>

      <div id="service" className="carddiv">
        <div className="container-xl text-center">
          <div className="carddivhead mx-auto">
            <h1>Services</h1>
            <h5></h5>
          </div>
          <div className="row mx-auto">
            <div className="p-3 col-12 col-sm-6 col-md-6 col-lg-4">
              <div className="card">
                <div className="cardcont">
                  {/* <h3></h3> */}
                  <h5>
                    1. Absorbția apei fluviale se realizează până la prima pânză
                    de apă, unde se introduce un filtru de piatră de 4.8 coarți.
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3 col-12 col-sm-6 col-md-6 col-lg-4">
              <div className="card">
                <div className="cardcont">
                  {/* <h3></h3> */}
                  <h5>
                    2. Efectuăm foraje cu cea mai mare expertiză, oferind
                    garanție și factură pentru forajele de apă potabilă.
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3 pl-0 col-12 col-sm-6 col-md-6 col-lg-4">
              <div className="card">
                <div className="cardcont">
                  {/* <h3></h3> */}
                  <h5>
                    3. Realizăm denisipări folosind pompe mamut și pompe
                    manuale. Cu pompa mamut, se realizează o coloană de apă
                    de minim 8 metri.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact" className="contact">
        <div className="container-">
          <div className="contacthead">
            <h1 className="pb-4">Contact</h1>
            <div className="allcontact mx-auto">
              <div
                className="row mx-auto"
                style={{ justifyContent: "space-between" }}
              >
                <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                  <form className="row g-2" onSubmit={handleSubmit}>
                    <h3>Send Me Message</h3>
                    <div className="col-md-6">
                      <input
                        type="email"
                        id="email_id"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        id="fullName"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        type="text"
                        id="message"
                        className="form-control"
                        placeholder="Message"
                        rows="4"
                        cols="30"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn btn-light mt-2 mb-3">
                        Send
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <div className="smedia">
                    <h3>Social Media</h3>
                    {/* <div className=""> */}
                    <div className="contacticon row">
                      <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                        <div className="iconbox">
                          <a
                            className=""
                            href="https://wa.link/ntd0sp"
                            target="_blank"
                          >
                            <i className="bi bi-whatsapp"></i> +40767239324
                          </a>
                          <a
                            className=""
                            href="https://www.instagram.com/aquadrill2024/"
                            target="_blank"
                          >
                            <i className="bi bi-instagram"></i> aquadrill2024
                          </a>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                        <div className="iconbox">
                          <a
                            className=""
                            href="https://www.facebook.com/profile.php?id=61557947392595"
                            target="_blank"
                          >
                            <i className="bi bi-facebook"></i> Foraje-Puturi
                          </a>
                          {/* <a className="" href="Linkedin">
                            <i className="bi bi-linkedin"></i> Linkedin
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
